.profile{
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #D9D9D9;
    background-color: #e7e7e7;
}
.section__profile{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 40px 30px;


}
.profile__img{
    margin: 30px 0;
    width: 100px;
    border: 4px solid #fff;
    border-radius: 50%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
    display: none;
    
}

.name{
    text-align: center;
    font-weight: 600;
    font-size: 18px;
}
.info-el{
    padding: 20px;
    background-color: #fff;
    margin-bottom: 10px;

    width: 100%;
    padding: 25px 30px;
    border-radius: 10px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.04);
    border: 2px solid #B9B9B9;
}

.logout{
    margin: 20px 0 0 0;
    width: 100%;
    font-size: 16px;
    background-color: #000;
    text-align: center;
    color: #fff;
    padding: 18px 0;
    border-radius: 15px;

}
.logout:hover{
    background-color: #2e2e2e;
}


.verify{
    margin: 20px 0 0 0;
    width: 100%;
    font-size: 16px;
    background-color: #1F800C;
    text-align: center;
    color: #fff;
    padding: 18px 0;
    border-radius: 15px;

}
.verify:hover{
    background-color: #2e2e2e;
}


.area3{
    width: 100%;
    height: 100px;
    background-color: #e7e7e7;
}


@media only screen and (min-width: 768px) {
    .verify {
        width: 440px;
    }
    .logout{
        width: 440px;
        
    }
  }

@media only screen and (min-width: 1024px) {
    .verify{
        display: none;
    }
}


.link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}

/* Стиль для текста персонального кода */
.info-el .personal-code {
    color: #1F800C; /* Цвет текста, чтобы соответствовать кнопке "verify" */
    text-decoration: underline;
    cursor: pointer;
    font-weight: 600; /* Чтобы подчеркнуть важность */
}

/* Модальное окно */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Обеспечить, чтобы модальное окно было выше других элементов */
}

.modal-content {
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    width: 90%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
}

.modal-content h2 {
    margin: 0 0 20px;
    font-size: 20px;
    font-weight: 600;
}

.modal-content input {
    width: calc(100% - 20px); /* Учитываем отступы */
    padding: 10px;
    border: 1px solid #B9B9B9;
    border-radius: 5px;
    margin-bottom: 20px;
    font-size: 16px;
}

.modal-content button {
    background-color: #1F800C;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 10px;
}

.modal-content button:hover {
    background-color: #2e2e2e;
}

.modal-content .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: transparent;
    border: none;
    font-size: 20px;
    cursor: pointer;
    color: #B9B9B9;
}
